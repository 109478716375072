const state = {
  selectedVegaPopupTranslation: {},
  hasVegaPopupTranslationUpdated: false,
  selectedLanguageForVegaPopup: { code: 'en', lnFullText: 'English', lnText: 'ENG' },
  hasLoadedVegaPopupTranslation: null
}

const getters = {
  selectedVegaPopupTranslation: (state) => state.selectedVegaPopupTranslation,
  hasVegaPopupTranslationUpdated: (state) => state.hasVegaPopupTranslationUpdated,
  selectedLanguageForVegaPopup: (state) => state.selectedLanguageForVegaPopup,
  hasLoadedVegaPopupTranslation: (state) => state.hasLoadedVegaPopupTranslation
}

const mutations = {
  SET_VEGA_POPUP_TRANSLATION: (state, payload) => {
    state.selectedVegaPopupTranslation = payload
  },
  SET_HAS_VEGA_POPUP_TRANSLATION_UPDATED: (state, payload) => {
    state.hasVegaPopupTranslationUpdated = payload
  },
  UPDATE_VEGA_POPUP_TRANSLATION(state, payload) {
    Object.assign(state.selectedVegaPopupTranslation, payload)
  },
  SET_DEFAULT_VEGA_POPUP_TRANSLATION: (state, payload) => {
    if (payload === 'en') {
      state.selectedVegaPopupTranslation = {
        vegaMessage: "Do you have any questions & wish live consultation? Let's connect now via:",
        vegaText: 'Hello 👋'
      }
    }
    if (payload === 'de') {
      state.selectedVegaPopupTranslation = {
        vegaMessage: 'Haben Sie Fragen und wünschen eine Live-Beratung? Lassen Sie uns jetzt über folgende Optionen verbinden:',
        vegaText: 'Hallo 👋'
      }
    }

    if (payload !== 'en' && payload !== 'de') {
      state.selectedVegaPopupTranslation = {
        vegaMessage: '',
        vegaText: ''
      }
    }
  },
  SET_SELECTED_LANGUAGE_FOR_VEGA_POPUP(state, payload) {
    state.selectedLanguageForVegaPopup = payload
  },
  SET_LOADED_VEGA_POPUP_TRANSLATION(state, payload) {
    state.hasLoadedVegaPopupTranslation = payload
  }
}

const actions = {
  setVegaPopupTranslation({ commit }, payload) {
    commit('SET_VEGA_POPUP_TRANSLATION', payload)
  },
  setHasVegaPopupTranslationUpdated({ commit }, payload) {
    commit('SET_HAS_VEGA_POPUP_TRANSLATION_UPDATED', payload)
  },
  updateVegaPopupTranslation({ commit }, payload) {
    commit('UPDATE_VEGA_POPUP_TRANSLATION', payload)
  },
  setDefaultVegaPopupTranslation({ commit }, payload) {
    commit('SET_DEFAULT_VEGA_POPUP_TRANSLATION', payload)
  },
  setSelectedLanguageForVegaPopup({ commit }, payload) {
    commit('SET_SELECTED_LANGUAGE_FOR_VEGA_POPUP', payload)
  },
  setLoadedVegaPopupTranslation({ commit }, payload) {
    commit('SET_LOADED_VEGA_POPUP_TRANSLATION', payload)
  }
}

export const vegaPopupTranslation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
