/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const getters = {
  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: (state) => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl'
    else if (state.windowWidth >= 992) return 'lg'
    else if (state.windowWidth >= 768) return 'md'
    else if (state.windowWidth >= 576) return 'sm'
    else return 'xs'
  },

  scrollbarTag: (state) => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  },
  company: (state) => {
    return state.company
  },

  visitors: (state) => {
    return state.visitors.list
  },
  incoming: (state) => {
    const agentId = state.AppActiveUser && state.AppActiveUser.uid
    if (!agentId) {
      return state.visitors.incoming
    }

    return state.visitors.incoming.filter((x) => (x.declinedByAgents && !x.declinedByAgents.includes(agentId)) || !x.declinedByAgents)
  },
  staticIncoming: (state) => {
    const agentId = state.AppActiveUser && state.AppActiveUser.uid
    if (!agentId) {
      return state.visitors.static
    }

    return state.visitors.static.filter((x) => (x.declinedByAgents && !x.declinedByAgents.includes(agentId)) || !x.declinedByAgents)
  },
  b2bEnabled: (state) => {
    return state.visitors.b2bEnabled
  },
  showOnlineVisitors: (state) => {
    return state.visitors.showOnlineVisitors
  },
  dateRange: (state) => {
    return state.visitors.dateRange
  },
  dateRangeIncoming: (state) => {
    return state.visitors.dateRangeIncoming
  },
  unreadNotifications: (state) => {
    return state.notifications
  },
  hasAudioAccess: (state) => {
    return state.audioAccess
  },
  meetings: (state) => {
    return state.meetings
  },
  leads: (state) => {
    return state.leads
  },
  all_dialogs: (state) => {
    return state.all_dialogs
  },
  dialogs: (state) => {
    return state.dialogs
  },
  abtests: (state) => {
    return state.abtests
  },
  getCurrentDialog: (state, getters) => (id) => getters.dialogs.find((dialog) => dialog.id === id),
  campaigns: (state) => {
    return state.campaigns
  },

  users: (state) => {
    return state.users
  },
  invoices: (state) => {
    return state.invoices
  },
  subscription: (state) => {
    return state.subscription
  },
  subscription_plans: (state) => {
    return state.subscription_plans
  },
  refresh: (state) => {
    return state.refresh
  },
  activeUser: (state) => {
    return state.AppActiveUser
  },
  assignment_rules: (state) => {
    return state.assignment_rules
  },
  user_groups: (state) => {
    return state.user_groups
  },
  chatFilesList: (state) => state.files,
  chatFilesListCurrent: (state, getters) => (index) => getters.chatFilesList[index],
  hasWhiteLabel: (state, getters) => {
    if (!getters.company) {
      return false
    }

    let claims = getters.company.claims || []
    const custom_claims = getters.company.claims_custom || []
    claims = [...new Set(claims.concat(custom_claims))]

    return (
      getters.company &&
      getters.company.claims &&
      getters.company.planType !== 'trial' &&
      getters.company.paymentStatus !== 'trial' &&
      claims.includes('white-label') &&
      getters.company.expiryDate &&
      dayjs().isBefore(dayjs.unix(getters.company.expiryDate.seconds))
    )
  },
  call_center_settings: (state) => {
    return state.call_center_settings
  },
  call_center_user_worktime: (state) => {
    return state.call_center_user_worktime
  },
  agency_settings: (state) => {
    return state.agency_settings
  },
  visitorIsTyping: (state) => {
    return state.visitorIsTyping
  },
  visitorId: (state) => {
    return state.visitorId
  },
  visitor: (state) => {
    return state.visitor
  },
  callCenterAgentsToBeNotified: (state) => {
    return state.callCenterAgentsToBeNotified
  },
  onlineUsers: (state) => {
    return state.onlineUsers.map((x) => {
      const res = {
        company: x.company,
        state: x.state,
        userId: x.userId
      }
      if (x.displayName) {
        res.displayName = x.displayName
      }
      if (x.photoURL) {
        res.photoURL = x.photoURL
      }
      if (x.ccstate) {
        res.ccstate = x.ccstate
      }
      return res
    })
  },
  banners: (state) => {
    return state.banners
  },
  swiperOptions: (state) => {
    return state.swiperOptions
  },
  dialog: (state) => state.dialog,
  campaign: (state) => state.campaignWidget,
  campaignId: (state) => state.campaignId,
  isLoadingRegisterFunnel: (state) => {
    return state.isLoadingRegisterFunnel
  },
  lockedUI: (state) => {
    return state.lockedUI
  },
  subscriptionCancelled: (state) => {
    return state.subscriptionCancelled
  },
  currency: (state) => {
    return state.currency
  },
  chatVisitors: (state) => {
    return state.chatVisitors
  },
  appSumoSubscriptionRefunded: (state) => {
    return state.appSumoSubscriptionRefunded
  },
  appSumoSubscriptionDeleteUsers: (state) => {
    return state.appSumoSubscriptionDeleteUsers
  },
  widgetsFilterForVisitor: (state) => state.widgetsFilterForVisitor,
  widgetsAssigned: (state) => {
    return state.widgetsAssigned
  },
  widgetsAssignedKey: (state) => {
    return state.widgetsAssignedKey
  },
  hasFilesizeError: (state) => {
    return state.hasFilesizeError
  },
  hasUserInteractedWithDOM: (state) => {
    return state.hasUserInteractedWithDOM
  },
  visitorsLastUpdated: (state) => {
    return state.visitors.visitorsLastUpdated
  },
  showRatingScreen: (state) => {
    return state.showRatingScreen
  },
  apiKeys: (state) => {
    return state.apiKeys
  },
  audioOutputDevices: (state) => {
    return state.audioOutputDevices
  },
  showChangePlanUI: (state) => state.showChangePlanUI,
  chosenPlan: (state) => state.chosenPlan,
  accountVatNumber: (state) => state.accountVatNumber,
  appliedPromoCode: (state) => state.appliedPromoCode,
  selectedPaymentMethod: (state) => state.selectedPaymentMethod,
  newUidFlowStep: (state) => state.newUidFlowStep,
  showPaymentFailedUI: (state) => state.showPaymentFailedUI,
  isSearchVisitorsOn: (state) => state.isSearchVisitorsOn,
  isSearchGptChatsOn: (state) => state.isSearchGptChatsOn,
  searchVisitors: (state) => {
    return state.searchVisitors.slice().sort((a, b) => b.lastRequestDate - a.lastRequestDate)
  },
  selectedVegaWidgetId: (state) => state.selectedVegaWidgetId,
  selectedVegaWidget: (state) => state.selectedVegaWidget,
  vegaPopups: (state) => state.vegaPopups,
  selectedVegaWidgetPopupId: (state) => state.selectedVegaWidgetPopupId,
  selectedVegaWidgetPopup: (state) => state.selectedVegaWidgetPopup,
  vegaPopupName: (state) => state.vegaPopupName,
  isVegaPopupEnable: (state) => state.isVegaPopupEnable,
  vegaPopupThreshold: (state) => state.vegaPopupThreshold,
  vegaPopupText: (state) => state.vegaPopupText,
  vegaPopupMessage: (state) => state.vegaPopupMessage,
  vegaPopupPosition: (state) => state.vegaPopupPosition,
  isVegaPopupWithoutImage: (state) => state.isVegaPopupWithoutImage,
  showMollieVega: (state) => state.showMollieVega,
  vegaHasDailyLimit: (state) => state.vegaHasDailyLimit,
  vegaDailyLimit: (state) => state.vegaDailyLimit,
  vegaCurrentVisitorsForTheDay: (state) => state.vegaCurrentVisitorsForTheDay,
  selectedAccount: (state) => state.selectedAccount,
  accountList: (state) => state.accountList,
  selectedAccountApiKeys: (state) => state.selectedAccountApiKeys,
  selectedBillingCountry: (state) => state.selectedBillingCountry,

  gptChats: (state) => {
    return state.gptChats
  },
  searchGptChats: (state) => state.searchGptChats,
  vegaLockingTime: (state) => state.vegaLockingTime
}

export default getters
