<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <ChoosePricingPlan v-if="showChangePlanUI" />
    <div id="pa-overlay"></div>
    <!--
      This is used to show modal using portal component, so that modal can displayed correctly in this div.
      Used is displaying modals in ab-testing
    -->
    <UILockedStatus v-if="lockedUI && showLockedUI" />
    <div id="portal-target" style="position: relative; z-index: 54000"></div>
    <div id="connected-agent-info" style="position: fixed; z-index: 54000; top: 50%; left: 100%"></div>
    <audio id="pa-audio-access" :src="require('@/assets/audio/nothing.wav')"></audio>
    <router-view @setAppClasses="setAppClasses" v-show="!showChangePlanUI" :key="appKey" />
  </div>
</template>

<script>
const INCOMING_REQUEST_NOTIFICATION_CLICK = 'INCOMING_REQUEST_NOTIFICATION_CLICK'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

import themeConfig from '@/../themeConfig.js'
import * as Sentry from '@sentry/vue'
import { mapGetters, mapMutations } from 'vuex'
import { commonFunctions } from '@/mixins/commonFunctions'
import { currency } from '@/mixins/currency'
import { msubscription } from '@/mixins/msubscription'
import { mvisitors } from '@/mixins/mvisitors'
import UILockedStatus from '@/components/UILockedStatus'
import ChoosePricingPlan from '@/components/plans/ChoosePricingPlan.vue'

import { Device } from '@capacitor/device'
import { PushNotifications } from '@capacitor/push-notifications'
import { Preferences } from '@capacitor/preferences'

export default {
  components: {
    UILockedStatus,
    ChoosePricingPlan
  },
  data() {
    return {
      vueAppClasses: [],
      showLockedUI: false,
      hasSetSentryUser: false,
      inactiveSeconds: 0,
      timer: null,
      appKey: Math.random().toString(36).substring(2, 15)
    }
  },
  mixins: [commonFunctions, currency, mvisitors, msubscription],
  watch: {
    showRatingScreen() {
      this.updateOnlineStatus()
    },
    'activeUserInfo.language'() {
      if (this.activeUserInfo && this.activeUserInfo.language && this.$i18n && this.$i18n.locale && this.$i18n.locale !== this.activeUserInfo.language) {
        this.$i18n.locale = this.activeUserInfo.language
      }
    },
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
    async 'activeUserInfo.company'() {
      if (this.activeUserInfo && this.activeUserInfo.company) {
        await this.getDefaultAppLanguages()
        this.getSavedTranslations()
        this.vegaFetchTotalVisitors()
        if (!this.activeUserInfo.impersonated) {
          await this.$db
            .collection('company')
            .doc(this.activeUserInfo.company)
            .set({ lastActivity: new Date(), lastActivityKey: Math.random().toString(36).substring(2, 15) }, { merge: true })
        }
      }
    },
    'activeUserInfo.isActiveConsultant'() {
      this.updateOnlineStatus()
    },
    company() {
      if (!this.hasSetSentryUser && this.activeUserInfo && this.activeUserInfo.company && this.company && this.company.name) {
        this.hasSetSentryUser = true
        const user = {
          id: this.activeUserInfo.uid,
          username: this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
          email: this.activeUserInfo.email,
          company: this.activeUserInfo.company,
          company_name: this.company.name,
          user_type: 'agent'
        }
        Sentry.setUser(user)
      }
      this.redirectToRegisterIfRequired()
    },

    'company.id'() {
      if (this.activeUserInfo && this.activeUserInfo.uid && this.company && this.company.id) {
        /* Check for existing calls where were not ended */
        this.checkExistingUnendedCalls()
      }
    },

    $route() {
      this.$analytics.setCurrentScreen(this.$i18n.t(this.$route.meta.pageTitle))
      this.$analytics.logEvent('page_view')
      this.$analytics.logEvent('screen_view', {
        app_name: this.isPWA() ? 'pwa' : 'web',
        screen_name: this.$i18n.t(this.$route.meta.pageTitle)
      })

      this.redirectToRegisterIfRequired()
      this.checkAccountStatus()
    },
    'company.paymentStatus'() {
      this.checkAccountStatus()
    },
    'company.expiryDate'() {
      this.checkAccountStatus()
    }
  },
  methods: {
    ...mapMutations({
      updateShowChangePlanUI: 'UPDATE_SHOW_CHANGE_PLAN_UI',
      updateChosenPlan: 'UPDATE_CHOSEN_PLAN',
      setNewUidFlowStep: 'SET_NEW_UID_FLOW_STEP',
      setPaymentFailedUI: 'SET_PAYMENT_FAILED_UI'
    }),
    resetInactivityTimer() {
      clearInterval(this.timer) // Clear any previous timer
      this.inactiveSeconds = 0 // Reset inactivity seconds
      this.lastCheckedSeconds = 0
      this.timer = setInterval(() => {
        this.inactiveSeconds += 5

        if (this.inactiveSeconds > this.lastCheckedSeconds + 900) {
          this.lastCheckedSeconds = this.inactiveSeconds
          /* If the app is inactive for more than 15 mins then reload the app */
          // eslint-disable-next-line
          console.log(`app inactive for more than ${this.inactiveSeconds / 60} minutes`)
          // window.location.reload()
          if (this.activeUserInfo && this.activeUserInfo.uid && this.activeUserInfo.company) {
            //console.log(`Keep alive /users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`)
            this.$firebase
              .database()
              .ref(`/users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`)
              .update({ keepAlive: this.$firebase.database.ServerValue.TIMESTAMP })
            this.$db.collection('users').doc(this.activeUserInfo.uid).update({ keepAlive: Date.now() })
          }
        }
      }, 5000)
    },
    handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        this.resetInactivityTimer()
      }
    },
    async setValueToDataStorage(key, value) {
      try {
        await Preferences.set({
          key,
          value
        })
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async getValueFromDataStorage(key) {
      try {
        const { value } = await Preferences.get({ key })

        return value
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async removeValueFromDataStorage(key) {
      try {
        await Preferences.remove({ key })
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async setMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed', this.onPushNotification)
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async deleteNotificationsToken() {
      try {
        const { platform } = await Device.getInfo()

        if (!['ios', 'android'].includes(platform)) {
          return
        }

        const { uuid } = await Device.getId()

        const value = await this.getValueFromDataStorage('notificationsToken')
        const tokens = this.activeUserInfo.tokens || []
        const filteredTokens = tokens.filter((token) => token.uuid !== uuid && token.token !== value)

        await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ tokens: filteredTokens }, { merge: true })
        await this.removeValueFromDataStorage('notificationsToken')
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    onPushNotification(notificationEntity) {
      if (!notificationEntity) {
        return
      }

      const { notification } = notificationEntity

      if (notification && notification.data && notification.data.aps && notification.data.aps.category === INCOMING_REQUEST_NOTIFICATION_CLICK) {
        this.$router.push('/incoming-requests')
      }
    },

    async getSavedTranslations() {
      /* Get saved translations from database */
      const translationDoc = await this.$db.collection('translations').doc(this.activeUserInfo.company).get()
      if (translationDoc && translationDoc.data()) {
        const languagesRef = await this.$db.collection('translations').doc(this.activeUserInfo.company).collection('languages').get()
        languagesRef.forEach((doc) => {
          const language = doc.data()
          const default_lang = JSON.parse(JSON.stringify(this.default_en))
          if (language.appointmentBooking && Object.keys(language.appointmentBooking).length > 0 && default_lang && default_lang.appointmentBooking) {
            for (const prop in default_lang.appointmentBooking) {
              if (!Object.prototype.hasOwnProperty.call(language.appointmentBooking, prop)) {
                language.appointmentBooking[prop] = ''
              }
            }
          }

          if (language.buttonAndHints && Object.keys(language.buttonAndHints).length > 0 && default_lang && default_lang.buttonAndHints) {
            for (const prop in default_lang.buttonAndHints) {
              if (!Object.prototype.hasOwnProperty.call(language.buttonAndHints, prop)) {
                language.buttonAndHints[prop] = ''
              }
            }
          }

          if (language && language.ln) {
            this.loadTranslation(language)
          }
        })
      }
    },

    async checkAccountStatus() {
      if (this.company && this.company.paymentStatus) {
        const isSubscriptionExpired = dayjs().tz(this.$defaultTimezone).isSameOrAfter(dayjs.unix(this.company.expiryDate.seconds), 'day')
        const isInvoiceUnpaid = this.company.paymentStatus === 'unpaid'

        const isAccountOnTrial = this.company && this.company.paymentStatus === 'on-trial'

        if (isAccountOnTrial && isSubscriptionExpired) {
          this.$store.commit('UPDATE_SUBSCRIPTION_CANCELLED', true)
          return
        }

        if (isInvoiceUnpaid || (isSubscriptionExpired && (this.company.currentInvoiceId || this.company.mollieSubscriptionId))) {
          this.$store.commit('UPDATE_LOCKED_UI', true)
          this.showLockedUI = true
        } else {
          this.$store.commit('UPDATE_LOCKED_UI', false)
          this.showLockedUI = false
        }

        const isPaymentExpired = this.company.paymentStatus === 'expired'
        if (isPaymentExpired) {
          this.$store.commit('UPDATE_SUBSCRIPTION_CANCELLED', true)
        } else {
          this.$store.commit('UPDATE_SUBSCRIPTION_CANCELLED', false)
        }

        const isPaymentFailed = this.company.paymentStatus === 'failed' || this.company.paymentStatus === 'canceled'

        if (isPaymentFailed) {
          if (this.company && this.company.failedPlanId && this.activeUserInfo.company) {
            const selectedPlan = this.allPlans.find((item) => item.id === this.company.failedPlanId)
            if (selectedPlan) {
              this.updateChosenPlan(selectedPlan)
              this.setPaymentFailedUI(true)
              this.updateShowChangePlanUI(true)
              this.setNewUidFlowStep(3)
            } else {
              this.$store.commit('UPDATE_SUBSCRIPTION_CANCELLED', true)
            }
          } else {
            this.$store.commit('UPDATE_SUBSCRIPTION_CANCELLED', true)
          }
        }

        const isAppSumoSubscriptionRefunded = this.company.isAppSumoAccount && this.company.appsumoUUID && this.company.paymentStatus === 'appsumo-refunded'
        if (isAppSumoSubscriptionRefunded) {
          this.$store.commit('UPDATE_APPSUMO_SUBSCRIPTION_REFUNDED', true)
        } else {
          this.$store.commit('UPDATE_APPSUMO_SUBSCRIPTION_REFUNDED', false)
        }
      }
    },

    async logoutUser() {
      await this.deleteNotificationsToken()

      window.location.href = '/pages/login'
    },
    redirectToRegisterIfRequired() {
      /* If the user has created an account without invoice then redirect to step 3 of registration */
      if (this.$route.name === 'page-register') {
        return
      }

      /* NEW FLOW: TRIAL USERS */
      /* IN NEW FLOW, there is no Step 4 except for Lottery Winners. Accounts by default have paymentStatus 'on-trial' */
      if (this.company && !this.company.IS_LOTTERY_WINNER) {
        return
      }

      if (
        this.company &&
        this.company.created &&
        (!this.company.paymentStatus || this.company.paymentStatus === 'on-trial') &&
        !this.company.isFirstPaymentCreated &&
        !this.company.currentInvoiceId &&
        !this.company.subscriptionId &&
        !this.company.subscriptionPlanId &&
        !this.company.shopifyStoreId &&
        !this.company.appsumoUUID &&
        this.company.planType !== 'partner' &&
        this.$route.name !== 'dialog-after-successfull-registration' &&
        !(this.$route.name === 'page-register' && this.$route.query && this.$route.query.step === '3') &&
        window.location.pathname !== '/pages/register' &&
        window.location.search.indexOf('step=3') === -1
      ) {
        this.$router.push({ name: 'page-register', query: { step: 3, lang: this.$i18n.locale } })
      }
    },
    isPWA() {
      return window && window.matchMedia('(display-mode: standalone)').matches
    },
    updateOnlineStatus() {
      if (this.activeUserInfo && this.activeUserInfo.company && this.activeUserInfo.uid && this.activeUserInfo.role !== 'view-only') {
        let displayName = this.activeUserInfo.firstname
        if (displayName && this.activeUserInfo.lastname) {
          displayName = `${displayName} ${this.activeUserInfo.lastname}`
        }

        const isIncomingRequestsEnabled = typeof this.activeUserInfo.isActiveConsultant === 'undefined' ? true : this.activeUserInfo.isActiveConsultant

        const _online = {
          state: this.showRatingScreen || !isIncomingRequestsEnabled ? 'offline' : 'online',
          displayName: this.activeUserInfo.displayName || displayName || this.activeUserInfo.email,
          last_changed: this.$firebase.database.ServerValue.TIMESTAMP
        }
        this.$firebase.database().ref(`/users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`).update(_online)
      }
    },
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
    playBlankAudio() {
      const vm = this
      if (!vm.hasAudioAccess) {
        const audioElement = document.getElementById('pa-audio-access')
        audioElement.play()
        vm.$store.commit('UPDATE_AUDIO_ACCESS', true)
      }
    },
    setCookie() {
      const tc = this.getParameterByName('tc')
      const cc = this.getParameterByName('cc')
      if (tc) {
        document.cookie = `afid=${tc};path=/;domain=${location.hostname}`
        if (cc) {
          document.cookie = `cpid=${cc};path=/;domain=${location.hostname}`
        }
      }
    }
  },
  mounted() {
    this.resetInactivityTimer() // Initialize inactivity timer when component mounts
    // Add event listeners for user activity
    window.addEventListener('mousemove', this.resetInactivityTimer)
    window.addEventListener('keypress', this.resetInactivityTimer)
    window.addEventListener('touchstart', this.resetInactivityTimer)
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    this.detectCurrency()

    /* Add Mollie js */
    const mollieScript = document.createElement('script')
    mollieScript.setAttribute('src', 'https://js.mollie.com/v1/mollie.js')
    document.head.appendChild(mollieScript)
    const vm = this
    vm.toggleClassInBody(themeConfig.theme)
    vm.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    window.addEventListener('custom_gtm_events', (event) => {
      if (event.info) {
        /* eslint-disable no-undef */
        dataLayer.push({ event: event.info.name, gtm: event.info.gtm })
      }
    })

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    /* Start blank audio to gain access autoplay */
    document.body.addEventListener('touchstart', vm.playBlankAudio, false)
    document.body.addEventListener('click', vm.playBlankAudio, false)
    vm.setCookie()

    if (window.onlineUserStatusDelayInterval) {
      window.clearInterval(window.onlineUserStatusDelayInterval)
    }

    window.onlineUserStatusDelayInterval = setInterval(() => {
      this.updateOnlineStatus()
      this.$serverBus.$emit('set-user-status-online')
    }, 30000)

    this.checkAccountStatus()

    this.$serverBus.$on('hide-ui-locked-status', () => {
      this.showLockedUI = false
    })

    /* To record vega funnel paying process */
    this.$serverBus.$on('vega-funnel-paying-process', async (text) => {
      if (this.activeUserInfo && this.activeUserInfo.company) {
        const _activity = {
          user:
            this.activeUserInfo.displayName && !this.activeUserInfo.displayName.includes('@')
              ? this.activeUserInfo.displayName
              : `${this.activeUserInfo.firstName || ''} ${this.activeUserInfo.lastName || ''}`.trim(),
          companyId: this.activeUserInfo.company,
          message: text,
          created: dayjs().utc().toDate()
        }
        this.$db.collection('vega-funnel-tracking').doc(this.activeUserInfo.company).collection('tracking').add(_activity)
      }
    })

    /* Watch for logout event from another tab and logout the user and refresh page */
    const channel = new BroadcastChannel('app-data')
    channel.addEventListener('message', async (event) => {
      const data = event.data
      if (data && data.type === 'logout') {
        await this.logoutUser()
      }
    })
  },
  async created() {
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

    this.setMobileApp()
  },
  beforeDestroy() {
    this.$serverBus.$off('hide-ui-locked-status')
    this.$serverBus.$off('vega-funnel-paying-process')
    clearInterval(this.timer) // Clean up the timer
    // Remove event listeners
    window.removeEventListener('mousemove', this.resetInactivityTimer)
    window.removeEventListener('keypress', this.resetInactivityTimer)
    window.removeEventListener('touchstart', this.resetInactivityTimer)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)

    window.removeEventListener('custom_gtm_events')
  },
  computed: {
    ...mapGetters({
      hasAudioAccess: 'hasAudioAccess',
      activeUserInfo: 'activeUser',
      company: 'company',
      lockedUI: 'lockedUI',
      translations: 'languages/translations',
      showChangePlanUI: 'showChangePlanUI',
      showRatingScreen: 'showRatingScreen'
    })
  }
}
</script>
<style lang="scss">
#pa-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  cursor: pointer;
}
.vs-sidebar {
  max-width: 265px !important;

  @media (max-width: 1024px) {
    max-width: 275px !important;
  }
}
#content-area.content-area-reduced {
  margin-left: 265px !important;
}
.multi-streams-mixer {
  display: none;
}
</style>
